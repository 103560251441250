
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      codeArk :"",
      codeArkError : '',
      codeArkSociete : '',
      loaderEnabled: true,
    });
    
    let username = "";
    let password = "";
    
    store.dispatch(Actions.LOGOUT);

    window.localStorage.setItem('filterBordereau', "");
    window.localStorage.setItem('currentBord', "");
    window.localStorage.setItem('currentBordType', "");

    const submitButton = ref<HTMLElement | null>(null);

    const formLogin = ref<HTMLFormElement | null>(null);


    onMounted(async () => {
     
      if(router.currentRoute.value.params.redirect == "password" && router.currentRoute.value.params.token) router.push({ name: "loader", params: { redirect: "password", token: router.currentRoute.value.params.token }});

      state.loaderEnabled = false;

    });

    const test_api = async () => {
      if (!store.getters.currentUser.us_api_key_meraki) return false;
      const mOrga = await getAxios("/testapi?key="+store.getters.currentUser.us_api_key_meraki);
      if(mOrga.errors) {
        return false;
      } else {
        return true;
      }
    }


    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string()
        .email("L'identifiant doit être un email valide")
        .required("L'identifiant est obligatoire")
        .label("username"),
      password: Yup.string()
        .required("Le mot de passe est obligatoire")
        .label("Password"),
    });



    //Form submit function
    const onSubmitLogin = async (values) => {

      store.dispatch(Actions.LOGOUT);
      
      // Clear existing errors
      //store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(async () => {

            const xMeraki = await test_api();
            if (xMeraki) {
              Swal.fire({
                title: "Connexion établie", 
                text: "Bienvenue " + store.getters.currentUser.us_prenom + " " + store.getters.currentUser.us_nom +" sur espace Audioptic Deploy Meraki !",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Accèder à Audioptic Deploy",
                customClass: { confirmButton: "btn fw-bold btn-light-primary", },
              }).then(function () {
                if (router.currentRoute.value.params.redirect) {
                  const stringRec = (router.currentRoute.value.params.redirect) as string;
                  const token = router.currentRoute.value.params.token
                  if(token) {
                    router.push({ name: "loader", params: {redirect: stringRec, token: token} });
                  }else{
                    router.push({ name: stringRec });
                  }
                } else {
                  router.push({ name: "networks" });
                }
              });

            } else {

              Swal.fire({
                title: "Connexion établie", 
                text: "Bienvenue " + store.getters.currentUser.us_prenom + " " + store.getters.currentUser.us_nom +" sur espace Audioptic Deploy Meraki ! Votre clé Meraki ne semble pas focntionnelle, vous allez être rediriger vers votre page de profil afin de la mettre à jour.",
                icon: "warning",
                buttonsStyling: false,
                confirmButtonText: "Accèder à votre profil Audioptic Deploy",
                customClass: { confirmButton: "btn fw-bold btn-light-primary", },
              }).then(function () {
                router.push({ name: "profil" });
              });


            }





          })
          .catch(() => {
            Swal.fire({
              text: "Il semble y avoir une erreur, veuillez vérifier vous identifiants de connexion.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Retourner sur la page de connexion",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 500);

    

    };

    if (router.currentRoute.value.params.codeArk) {
      const stringRec = (router.currentRoute.value.params.codeArk) as string;
      state.codeArk = stringRec;
    } else {

      const mCodeArk = window.localStorage.getItem('codeArk');
      // console.log("mCodeArk ->", mCodeArk);
      if (mCodeArk) {
        router.push({ name: "sign-in", params: { 'codeArk': mCodeArk } })
        state.codeArk = mCodeArk;
      } 

    }

    return {
      onSubmitLogin,
      login,
      submitButton,
      state,
      username,
      password
    };
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
